<template>
	<div v-if="!registrationAllowed" class="container p-5">
		<div class="alert alert-danger">
			We are sorry - registration of new users is currently disabled.<br />
			Please try again in a while.<br />
			If you already have an account, please
			<router-link to="/auth/login">login in to your account</router-link>.
		</div>
	</div>
	<main v-else class="form-signin text-center">
		<form @submit="login">
			<h1 class="h3 mt-4 mb-3 fw-normal">Registration</h1>

			<div class="alert alert-warning" role="alert" v-if="error">
				An error occurred. Please check that the email is valid, that the
				password has a size of at least 8 and at most 50 characters, and that
				your Internet connection is working properly.
			</div>

			<div class="form-floating">
				<input
					type="email"
					class="form-control"
					id="registrationFormEmail"
					v-model="registrationForm.email"
					:disabled="loading"
					required
				/>
				<label for="registrationFormEmail">Email address</label>
			</div>
			<div class="form-floating">
				<input
					type="password"
					class="form-control"
					id="registrationFormPassword"
					v-model="registrationForm.password"
					:disabled="loading"
					required
				/>
				<label for="registrationFormPassword">Password</label>
			</div>

			<button
				class="w-100 btn btn-lg btn-primary"
				type="submit"
				:disabled="loading"
			>
				<span v-if="loading" class="spinner-border spinner-border-sm"></span>
				<span v-else>Register</span>
			</button>
			<p class="mt-4">
				<router-link to="/auth/login">Login in to your account</router-link>
			</p>
		</form>
	</main>
</template>
<script>
import { useCookies } from "vue3-cookies";
import axios from "axios";

export default {
	name: "Login",
	data() {
		return {
			loading: true,
			error: false,
			registrationAllowed: true,
			registrationForm: { email: "", password: "" },
		};
	},
	setup() {
		const { cookies } = useCookies();
		return { cookies };
	},
	created() {
		axios.get(`https://job-applications-manager-api.herokuapp.com/api/v1/auth/registration-allowed`).then(registrationAllowedResponse => {
			if(registrationAllowedResponse.data.allowed !== true) {
				this.registrationAllowed = false;
			}
			this.loading = false;
		})
	},
	methods: {
		login: async function (e) {
			e.preventDefault();
			this.error = false;
			this.loading = true;
			try {
				const response = await axios.post(
					`https://job-applications-manager-api.herokuapp.com/api/v1/auth/registration`,
					this.registrationForm
				);
				const jwt = response.data.token;
				const expirationDate = new Date(
					JSON.parse(atob(jwt.split(".")[1])).exp * 1000
				); // Convert UNIX time to Date
				this.cookies.set("token", jwt, expirationDate);
				this.$router.replace("/");
			} catch (e) {
				this.error = true;
			}
			this.loading = false;
		},
	},
};
</script>

<style scoped>
html,
body {
	height: 100%;
}

body {
	display: flex;
	align-items: center;
	padding-top: 40px;
	padding-bottom: 40px;
	background-color: #f5f5f5;
}

.form-signin {
	width: 100%;
	max-width: 330px;
	padding: 15px;
	margin: auto;
}

.form-signin .checkbox {
	font-weight: 400;
}

.form-signin .form-floating:focus-within {
	z-index: 2;
}

.form-signin input[type="email"] {
	margin-bottom: -1px;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
	margin-bottom: 10px;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.bd-placeholder-img {
	font-size: 1.125rem;
	text-anchor: middle;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
}

@media (min-width: 768px) {
	.bd-placeholder-img-lg {
		font-size: 3.5rem;
	}
}
</style>
