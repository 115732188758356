import { useCookies } from "vue3-cookies"
const { cookies } = useCookies()
import axios from 'axios'

export default {
  async passwordChange({ password, newPassword }) {
    await axios.post(
      `https://job-applications-manager-api.herokuapp.com/api/v1/auth/password-change`,
      { password, newPassword },
      { headers: { Authorization: `Bearer ${cookies.get("token")}` } }
    )
  }
}