import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { Chart, registerables } from "chart.js"
import { SankeyController, Flow } from "chartjs-chart-sankey"
Chart.register(...registerables, SankeyController, Flow)

createApp(App).use(router).use(store).mount('#app')
