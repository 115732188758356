<template>
	<section class="mb-5">
		<div
			class="
				d-flex
				justify-content-between
				flex-wrap flex-md-nowrap
				align-items-center
				pt-3
				pb-2
				mb-3
				border-bottom
			"
		>
			<h1 class="h2">Statistics</h1>
		</div>
		<LineChart
			:chartData="jobApplicationsByDateChartData"
			:options="jobApplicationsByDateChartOptions"
		/>
		<div class="sankey-chart">
			<canvas id="sankeyChart"></canvas>
		</div>
	</section>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { Chart } from "chart.js";

import Moment from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(Moment);
import { LineChart } from "vue-chart-3";

export default {
	name: "Dashboard",
	components: { LineChart },
	data() {
		return {
			jobApplicationsByDateChartData: {
				labels: [],
				datasets: [],
			},
			jobApplicationsByDateChartOptions: {
				backgroundColor: "#0d6efd",
				fill: true,
				tension: 0.4,
				pointRadius: 0,
				plugins: { tooltip: { intersect: false } },
			},
		};
	},
	created() {
		try {
			this.setupJobApplicationsByDateChart();
		} catch (e) {
			alert(e);
		}
	},
	mounted() {
		try {
			this.setupJobApplicationsSankeyChart();
		} catch (e) {
			alert(e);
		}
	},
	computed: {
		...mapState({
			jobApplications: (state) => state.jobApplications.jobApplications,
		}),
		jobApplicationsDates() {
			if (Object.keys(this.jobApplications).length == 0) return [];

			let minDate = Object.values(this.jobApplications)
				.filter((jobApplication) => jobApplication.applicationDate)
				.reduce(
					(prev, curr) =>
						prev.applicationDate.localeCompare(curr.applicationDate) < 0
							? prev
							: curr,
					{ applicationDate: moment().format("YYYY-MM-DD") }
				).applicationDate;
			let maxDate = Object.values(this.jobApplications)
				.filter((jobApplication) => jobApplication.applicationDate)
				.reduce(
					(prev, curr) =>
						prev.applicationDate.localeCompare(curr.applicationDate) > 0
							? prev
							: curr,
					{ applicationDate: moment().subtract(1, "year").format("YYYY-MM-DD") }
				).applicationDate;

			return Array.from(
				moment.range(moment(minDate), moment(maxDate)).by("day")
			);
		},
	},
	methods: {
		...mapActions("jobApplications", ["fetchJobApplications"]),
		formatDate: function (date) {
			return moment(date).format("DD/MM/YYYY");
		},
		numberOfJobApplicationsByDate: function (date) {
			return Object.values(this.jobApplications).filter(
				(jobApplication) =>
					this.formatDate(jobApplication.applicationDate) ==
					this.formatDate(date)
			).length;
		},
		setupJobApplicationsByDateChart: function () {
			this.jobApplicationsByDateChartData.datasets = [
				{ label: "Daily job applications", data: [] },
				{ label: "Cumulative job applications", data: [], hidden: true },
				{
					label: "Daily job applications average during past 7 days",
					data: [],
					hidden: true,
				},
			];

			let cumulativeNumberOfApplications = 0;
			let last7DaysNumberOfApplications = [];

			for (const [
				i,
				jobApplicationDate,
			] of this.jobApplicationsDates.entries()) {
				let date = moment(jobApplicationDate);
				let label =
					date.date() == 1 || i == 0 ? date.format("MMM") : date.date();
				let currentDateNumberOfApplications =
					this.numberOfJobApplicationsByDate(jobApplicationDate);

				// Label
				this.jobApplicationsByDateChartData.labels.push(label);

				// Daily job applications
				this.jobApplicationsByDateChartData.datasets[0].data.push(
					currentDateNumberOfApplications
				);

				// Cumulative job applications
				cumulativeNumberOfApplications += currentDateNumberOfApplications;
				this.jobApplicationsByDateChartData.datasets[1].data.push(
					cumulativeNumberOfApplications
				);

				// Last 7 days average job applications
				last7DaysNumberOfApplications.push(currentDateNumberOfApplications);
				if (last7DaysNumberOfApplications.length > 7) {
					// If there are now 8 elements, remove the first one
					last7DaysNumberOfApplications.shift();
				}
				const last7DaysAverage =
					last7DaysNumberOfApplications.reduce((a, b) => a + b, 0) /
					last7DaysNumberOfApplications.length;
				this.jobApplicationsByDateChartData.datasets[2].data.push(
					last7DaysAverage
				);
			}
		},
		setupJobApplicationsSankeyChart: function () {
			var ctx = document.getElementById("sankeyChart").getContext("2d");

			const SORTED_STEPS = [
				"applied",
				// 'applied_rejected',
				// 'applied_ghosted',
				"bi bi-telephone", // screening interview
				// 'bi bi-code-slash', // online assessment
				"bi bi-gear", // technical interview
				"bi bi-building", // onsite interview
				"bi bi-cash",
				// 'ghosted',
				"declined",
				"rejected",
			];
			const ICON_TO_STRING = {
				applied: "Applied",
				applied_rejected: "Applied (rejected)",
				applied_ghosted: "Applied (ghosted)",
				"bi bi-code-slash": "Assessment",
				"bi bi-telephone": "Screening",
				"bi bi-gear": "Technical",
				"bi bi-building": "Onsite",
				"bi bi-cash": "Offer",
				ghosted: "Ghosted",
				declined: "Declined",
				rejected: "Rejected",
			};
			let flowNumberAtStep = {
				applied: 0,
				applied_rejected: 0,
				applied_ghosted: 0,
				"bi bi-code-slash": 0,
				"bi bi-telephone": 0,
				"bi bi-gear": 0,
				"bi bi-building": 0,
				"bi bi-cash": 0,
				ghosted: 0,
				declined: 0,
				rejected: 0,
			};

			let flows = {};

			for (let i = 0; i < SORTED_STEPS.length; ++i) {
				let flowFrom = SORTED_STEPS[i];
				for (let j = i + 1; j < SORTED_STEPS.length; ++j) {
					let flowTo = SORTED_STEPS[j];

					// Don't take "application -> online assessment"
					if (flowFrom != "applied" || flowTo != "bi bi-code-slash") {
						let flow = [flowFrom, flowTo];
						flows[flow] = {
							from: flowFrom,
							to: flowTo,
							flow: 0,
						};
					}
				}
			}

			for (const jobApplication of Object.values(this.jobApplications)) {
				let lastStepIcon = "applied";
				if (jobApplication.steps.length > 0) {
					flowNumberAtStep["applied"] += 1;
				}
				for (const step of jobApplication.steps) {
					let flow = [lastStepIcon, step.icon];
					if (flow in flows) {
						flows[flow].flow += 1;
						flowNumberAtStep[step.icon] += 1;
						lastStepIcon = step.icon;
					}
				}
				if (jobApplication.rejected) {
					let flow = [
						lastStepIcon == "applied" ? "applied_rejected" : lastStepIcon,
						"rejected",
					];
					if (flow in flows) {
						flows[flow].flow += 1;
						flowNumberAtStep["rejected"] += 1;
					}
				} else if (jobApplication.declined) {
					let flow = [
						lastStepIcon == "applied" ? "applied_declined" : lastStepIcon,
						"declined",
					];
					if (flow in flows) {
						flows[flow].flow += 1;
						flowNumberAtStep["declined"] += 1;
					}
				} else if (jobApplication.steps.every((step) => step.completed)) {
					let flow = [
						lastStepIcon == "applied" ? "applied_ghosted" : lastStepIcon,
						"ghosted",
					];
					if (flow in flows) {
						flows[flow].flow += 1;
						flowNumberAtStep["ghosted"] += 1;
					}
				}
			}

			const data = Object.values(flows)
				.filter((dataObject) => dataObject.flow > 0)
				.map((dataObject) => ({
					from: `${ICON_TO_STRING[dataObject.from]} (${
						flowNumberAtStep[dataObject.from]
					})`,
					to: `${ICON_TO_STRING[dataObject.to]} (${
						flowNumberAtStep[dataObject.to]
					})`,
					flow: dataObject.flow,
				}));

			var colors = {
				[`Applied (${flowNumberAtStep["applied"]})`]: "black",
				[`Assessment (${flowNumberAtStep["bi bi-code-slash"]})`]: "brown",
				[`Screening (${flowNumberAtStep["bi bi-telephone"]})`]: "purple",
				[`Technical (${flowNumberAtStep["bi bi-gear"]})`]: "blue",
				[`Onsite (${flowNumberAtStep["bi bi-building"]})`]: "yellow",
				[`Offer (${flowNumberAtStep["bi bi-cash"]})`]: "green",
				[`Rejected (${flowNumberAtStep["rejected"]})`]: "red",
				[`Declined (${flowNumberAtStep["declined"]})`]: "orange",
				[`Ghosted (${flowNumberAtStep["ghosted"]})`]: "grey",
			};

			function getColor(name) {
				return colors[name] || "green";
			}

			if (data.length > 0) {
				new Chart(ctx, {
					type: "sankey",
					data: {
						datasets: [
							{
								data,
								colorFrom: (c) => getColor(c.dataset.data[c.dataIndex].from),
								colorTo: (c) => getColor(c.dataset.data[c.dataIndex].to),
								borderWidth: 0,
								size: "max",
							},
						],
					},
				});
			}
		},
	},
};
</script>

<style scoped>
.sankey-chart {
	max-width: 100%;
	max-height: 1600px;
}

canvas {
	user-select: none;
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
}
</style>