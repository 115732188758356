import { useCookies } from "vue3-cookies"
const { cookies } = useCookies()
import axios from 'axios'

const AUTH_HEADER = { Authorization: `Bearer ${cookies.get("token")}` }

export default {
  async fetchJobApplications() {
    let response = await axios.get(
      'https://job-applications-manager-api.herokuapp.com/api/v1/job-applications',
      { headers: AUTH_HEADER }
    )
    return response.data
  },
  async createJobApplication(jobApplication) {
    let response = await axios.post(
      'https://job-applications-manager-api.herokuapp.com/api/v1/job-application',
      jobApplication,
      { headers: AUTH_HEADER }
    )
    return response.data._id
  },
  async updateJobApplication({ jobApplication, jobApplicationId }) {
    await axios.post(
      `https://job-applications-manager-api.herokuapp.com/api/v1/job-application/${jobApplicationId}`,
      jobApplication,
      { headers: AUTH_HEADER }
    )
  },
  async deleteJobApplication(jobApplicationId) {
    await axios.delete(
      `https://job-applications-manager-api.herokuapp.com/api/v1/job-application/${jobApplicationId}`,
      { headers: AUTH_HEADER }
    )
  },
}