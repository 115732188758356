<template>
	<section class="mb-5">
		<div
			class="
				d-flex
				justify-content-between
				flex-wrap flex-md-nowrap
				align-items-center
				pt-3
				pb-2
				mb-3
				border-bottom
			"
		>
			<h1 class="h2">Profile</h1>
		</div>
		<form @submit="changePassword" style="max-width: 300px">
			<div
				class="alert alert-warning"
				role="alert"
				v-if="changePasswordForm.error"
			>
				An error occurred. Please check that the current password is correct,
				that the new password length is between 8 and 50 characters, and that
				your Internet connection is working properly.
			</div>

			<div class="form-floating mb-3">
				<input
					type="password"
					class="form-control"
					id="changePasswordFormCurrentPassword"
					v-model="changePasswordForm.password"
					required
				/>
				<label for="changePasswordFormCurrentPassword">Current password</label>
			</div>

			<div class="form-floating mb-3">
				<input
					type="password"
					class="form-control"
					id="changePasswordFormNewPassword"
					v-model="changePasswordForm.newPassword"
					required
				/>
				<label for="changePasswordFormNewPassword">New password</label>
			</div>

			<button
				class="w-100 btn btn-lg btn-primary"
				type="submit"
				:disabled="changePasswordForm.loading"
			>
				<span
					v-if="changePasswordForm.loading"
					class="spinner-border spinner-border-sm"
				></span>
				<span v-else>Change password</span>
			</button>
		</form>
	</section>
</template>

<script>
import api from "../../api/auth";

export default {
	name: "Profile",
	data() {
		return {
			changePasswordForm: {
				loading: false,
				error: false,
				password: "",
				newPassword: "",
			},
		};
	},
	methods: {
		changePassword: async function (e) {
			e.preventDefault();
			this.changePasswordForm.error = false;
			this.changePasswordForm.loading = true;
			try {
				await api.passwordChange({
					password: this.changePasswordForm.password,
					newPassword: this.changePasswordForm.newPassword,
				});
				this.changePasswordForm = {
					loading: false,
					error: false,
					password: "",
					newPassword: "",
				};
			} catch (e) {
				this.changePasswordForm.error = true;
			}
			this.changePasswordForm.loading = false;
		},
	},
};
</script>