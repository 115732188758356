<template>
	<section>
		<header
			class="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow"
		>
			<a class="navbar-brand col-md-3 col-lg-2 me-0 px-3" href="#"
				>Job apps manager</a
			>
			<button
				class="navbar-toggler position-absolute d-md-none collapsed"
				type="button"
				data-bs-toggle="collapse"
				data-bs-target="#sidebarMenu"
				aria-controls="sidebarMenu"
				aria-expanded="false"
				aria-label="Toggle navigation"
			>
				<span class="navbar-toggler-icon"></span>
			</button>
			<input
				class="form-control form-control-dark w-100"
				type="text"
				v-if="jobApplications !== null"
				placeholder="Search"
				aria-label="Search"
				v-model="searchText"
			/>
		</header>

		<div class="container-fluid">
			<div class="row">
				<nav
					id="sidebarMenu"
					class="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse"
				>
					<div class="position-sticky pt-3">
						<ul class="nav flex-column">
							<li class="nav-item">
								<router-link
									to="/"
									class="nav-link"
									:class="$route.path == '/' ? 'active' : ''"
									@click="refreshJobApplications"
								>
									<i class="bi bi-file-text"></i>
									Job applications
									<span v-if="jobApplications !== null"
										>({{ Object.keys(jobApplications).length }})</span
									>
									<div
										class="spinner-border spinner-border-sm ms-2"
										role="status"
										v-if="jobApplicationsLoading"
									>
										<span class="visually-hidden">Loading...</span>
									</div>
								</router-link>
							</li>
							<li class="nav-item">
								<router-link
									to="/statistics"
									class="nav-link"
									:class="$route.path == '/statistics' ? 'active' : ''"
									@click="refreshJobApplications"
								>
									<i class="bi bi-graph-up"></i>
									Statistics
								</router-link>
							</li>
							<li class="nav-item">
								<router-link
									to="/profile"
									class="nav-link"
									:class="$route.path == '/profile' ? 'active' : ''"
								>
									<i class="bi bi-person"></i>
									Profile
								</router-link>
							</li>
							<li class="nav-item">
								<a
									class="nav-link"
									aria-current="page"
									style="cursor: pointer"
									@click="logout"
								>
									<i class="bi bi-box-arrow-right"></i>
									Logout
								</a>
							</li>
						</ul>
					</div>
				</nav>

				<main
					v-if="jobApplications === null"
					class="col-md-9 ms-sm-auto col-lg-10 px-md-4 text-center"
				>
					<div class="spinner-border mt-5" role="status">
						<span class="visually-hidden">Loading...</span>
					</div>
				</main>
				<main v-else class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
					<div v-if="loadingDataError" class="alert alert-danger mt-4">
						An error occurred while loading data. Please, check your Internet
						connection and
						<a @click="refreshJobApplications" href="#"
							>click here to try again</a
						>. Keep in mind that the data might not be up-to-date right now.
					</div>
					<router-view />
				</main>
			</div>
		</div>
	</section>
</template>

<script>
import { useCookies } from "vue3-cookies";
import { mapState, mapActions } from "vuex";

export default {
	name: "Dashboard",
	data() {
		return {
			jobApplicationsLoading: false,
			loadingDataError: false,
			searchText: "",
		};
	},
	watch: {
		searchText(newValue) {
			this.setJobApplicationsSearchQuery(newValue);
		},
	},
	setup() {
		const { cookies } = useCookies();
		return { cookies };
	},
	created() {
		this.refreshJobApplications();
	},
	computed: {
		...mapState({
			jobApplications: (state) => state.jobApplications.jobApplications,
		}),
	},
	methods: {
		...mapActions("jobApplications", [
			"fetchJobApplications",
			"setJobApplicationsSearchQuery",
		]),
		refreshJobApplications: async function () {
			this.searchText = "";
			this.jobApplicationsLoading = true;
			try {
				await this.fetchJobApplications();
				this.loadingDataError = false;
			} catch (e) {
				this.loadingDataError = true;
			}
			this.jobApplicationsLoading = false;
		},
		logout: function () {
			if (confirm("Are you sure you want to logout?")) {
				this.cookies.remove("token");
				this.$router.replace("/auth/login");
			}
		},
	},
};
</script>

<style scoped>
body {
	font-size: 0.875rem;
}

.feather {
	width: 16px;
	height: 16px;
	vertical-align: text-bottom;
}

/*
 * Sidebar
 */

.sidebar {
	position: fixed;
	top: 0;
	/* rtl:raw:
  right: 0;
  */
	bottom: 0;
	/* rtl:remove */
	left: 0;
	z-index: 100; /* Behind the navbar */
	padding: 48px 0 0; /* Height of navbar */
	box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
}

@media (max-width: 767.98px) {
	.sidebar {
		top: 5rem;
	}
}

.sidebar-sticky {
	position: relative;
	top: 0;
	height: calc(100vh - 48px);
	padding-top: 0.5rem;
	overflow-x: hidden;
	overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

.sidebar .nav-link {
	font-weight: 500;
	color: #333;
}

.sidebar .nav-link .feather {
	margin-right: 4px;
	color: #727272;
}

.sidebar .nav-link.active {
	color: #2470dc;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
	color: inherit;
}

.sidebar-heading {
	font-size: 0.75rem;
	text-transform: uppercase;
}

/*
 * Navbar
 */

.navbar-brand {
	padding-top: 0.75rem;
	padding-bottom: 0.75rem;
	font-size: 1rem;
	background-color: rgba(0, 0, 0, 0.25);
	box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.25);
}

.navbar .navbar-toggler {
	top: 0.25rem;
	right: 1rem;
}

.navbar .form-control {
	padding: 0.75rem 1rem;
	border-width: 0;
	border-radius: 0;
}

.form-control-dark {
	color: #fff;
	background-color: rgba(255, 255, 255, 0.1);
	border-color: rgba(255, 255, 255, 0.1);
}

.form-control-dark:focus {
	border-color: transparent;
	box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.25);
}
</style>