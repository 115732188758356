import { createWebHashHistory, createRouter } from "vue-router"
import { useCookies } from "vue3-cookies"
const { cookies } = useCookies()

import Dashboard from "@/views/Dashboard.vue"
import JobApplications from "@/views/dashboard/JobApplications.vue"
import Statistics from "@/views/dashboard/Statistics.vue"
import Profile from "@/views/dashboard/Profile.vue"

import Auth from "@/views/Auth.vue"
import Login from "@/views/auth/Login.vue"
import Registration from "@/views/auth/Registration.vue"

function isUserLoggedin() {
  try {
    let token = cookies.get('token')
    if (token) {
      let user = JSON.parse(atob(token.split('.')[1]))
      if (user.exp < Date.now()) {
        return true
      }
    }
  } catch (e) {
    console.log(e)
  }
  return false
}

const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
    beforeEnter(to, from, next) {
      if (isUserLoggedin())
        next()
      else
        next('/auth/login')
    },
    children: [
      {
        path: "/",
        component: JobApplications
      },
      {
        path: "/statistics",
        component: Statistics
      },
      {
        path: "/profile",
        component: Profile
      }
    ]
  },
  {
    path: "/auth",
    name: "Auth",
    component: Auth,
    beforeEnter(to, from, next) {
      if (isUserLoggedin())
        next('/')
      else
        next()
    },
    children: [
      {
        path: "login",
        component: Login
      },
      {
        path: "registration",
        component: Registration
      }
    ]
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;