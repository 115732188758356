<template>
	<section>
		<div
			class="
				d-flex
				justify-content-between
				flex-wrap flex-md-nowrap
				align-items-center
				pt-3
				pb-2
				mb-3
				border-bottom
			"
		>
			<h1 class="h2">Dashboard</h1>
			<div class="btn-toolbar mb-2 mb-md-0">
				<button
					type="button"
					class="btn btn-primary"
					data-bs-toggle="modal"
					data-bs-target="#applicationModal"
					@click="clearApplicationForm"
				>
					Add application
				</button>
			</div>
		</div>

		<h3>To do ({{ todoJobApplications.length }})</h3>
		<table
			class="table table-hover table-sm mw-100"
			style="table-layout: fixed"
		>
			<thead>
				<tr>
					<th scope="col">Next step</th>
				</tr>
			</thead>
			<tbody>
				<tr
					v-for="jobApplication in todoJobApplications"
					:key="'job-application-' + jobApplication._id"
					:class="
						diffBetweenDates(getFirstNextStep(jobApplication).expirationDate) <
						0
							? 'table-danger'
							: diffBetweenDates(
									getFirstNextStep(jobApplication).expirationDate
							  ) == 0
							? 'table-warning'
							: diffBetweenDates(
									getFirstNextStep(jobApplication).expirationDate
							  ) == 1
							? 'table-primary'
							: ''
					"
				>
					<td>
						<div class="row">
							<div class="col-3 col-lg-2 col-xl-1">
								<span v-if="getFirstNextStep(jobApplication).expirationDate">
									{{
										formatDate(getFirstNextStep(jobApplication).expirationDate)
									}}
								</span>
								<span v-else> N/A </span>
							</div>
							<div class="col-3 col-lg-2 col-xl-1">
								<template
									v-if="
										diffBetweenDates(
											getFirstNextStep(jobApplication).expirationDate
										) == -1
									"
								>
									yesterday
								</template>
								<template
									v-else-if="
										diffBetweenDates(
											getFirstNextStep(jobApplication).expirationDate
										) < 0
									"
								>
									{{
										-diffBetweenDates(
											getFirstNextStep(jobApplication).expirationDate
										)
									}}
									days ago
								</template>
								<template
									v-else-if="
										diffBetweenDates(
											getFirstNextStep(jobApplication).expirationDate
										) == 0
									"
								>
									today
								</template>
								<template
									v-else-if="
										diffBetweenDates(
											getFirstNextStep(jobApplication).expirationDate
										) == 1
									"
								>
									tomorrow
								</template>
								<template
									v-else-if="getFirstNextStep(jobApplication).expirationDate"
								>
									{{
										diffBetweenDates(
											getFirstNextStep(jobApplication).expirationDate
										)
									}}
									days
								</template>
							</div>
							<div class="col-6 col-lg-2 pb-2 pb-lg-0 text-end">
								<span
									v-for="(step, i) in jobApplication.steps.filter(
										(step) => step.icon
									)"
									:key="`waiting-job-application-${jobApplication._id}-step-${i}`"
								>
									<i
										class="me-2"
										:class="
											step.icon +
											' ' +
											(!step.completed
												? 'text-white bg-dark bg-icon-circle'
												: '')
										"
									/>
								</span>
							</div>
							<div class="col-12 col-sm-3 col-lg-2 text-truncate">
								{{ jobApplication.companyName }}
							</div>
							<div class="col-8 col-sm-6 col-lg-2 col-xl-4 text-truncate">
								{{ jobApplication.jobTitle }}
							</div>
							<div class="col-4 col-sm-3 col-lg-2 text-end">
								<div class="btn-group" role="group">
									<button
										type="button"
										class="btn btn-sm btn-outline-success"
										:disabled="
											disabledButtons.markAsDone.has(jobApplication._id)
										"
										@click="markFirstTodoStepAsDone(jobApplication._id)"
									>
										<span
											v-if="disabledButtons.markAsDone.has(jobApplication._id)"
											class="spinner-border spinner-border-sm"
										></span>
										<i v-else class="bi bi-check"></i>
									</button>
									<a
										v-if="jobApplication.jobLink"
										:href="jobApplication.jobLink"
										class="btn btn-sm btn-outline-secondary"
										target="_blank"
									>
										<i class="bi bi-link-45deg"></i>
									</a>
									<button
										type="button"
										class="btn btn-sm btn-outline-primary"
										data-bs-toggle="modal"
										data-bs-target="#applicationModal"
										@click="fillApplicationForm(jobApplication._id)"
									>
										<i class="bi bi-pencil"></i>
									</button>
								</div>
							</div>
						</div>
					</td>
				</tr>
			</tbody>
		</table>

		<h3>Waiting response ({{ waitingForResponseJobApplications.length }})</h3>
		<table class="table table-hover table-sm" style="table-layout: fixed">
			<thead>
				<tr>
					<th scope="col">Last step</th>
				</tr>
			</thead>
			<tbody>
				<tr
					v-for="jobApplication in waitingForResponseJobApplications"
					:key="'job-application-' + jobApplication._id"
				>
					<td>
						<div class="row">
							<div class="col-3 col-lg-2 col-xl-1">
								{{
									formatDate(getLastCompletedStep(jobApplication).completedDate)
								}}
							</div>
							<div class="col-3 col-lg-2 col-xl-1">
								{{
									-diffBetweenDates(
										getLastCompletedStep(jobApplication).completedDate
									)
								}}
								days
							</div>
							<div class="col-6 col-lg-2 pb-2 pb-lg-0 text-end">
								<span
									v-for="(step, i) in jobApplication.steps"
									:key="`waiting-job-application-${jobApplication._id}-step-${i}`"
								>
									<i class="me-2" :class="step.icon" />
								</span>
							</div>
							<div class="col-12 col-sm-3 col-lg-2 text-truncate">
								{{ jobApplication.companyName }}
							</div>
							<div class="col-8 col-sm-6 col-lg-2 col-xl-4 text-truncate">
								{{ jobApplication.jobTitle }}
							</div>
							<div class="col-4 col-sm-3 col-lg-2 text-end">
								<div class="btn-group" role="group">
									<button
										type="button"
										class="btn btn-sm btn-outline-danger"
										:disabled="
											disabledButtons.markAsRejected.has(jobApplication._id)
										"
										@click="setJobApplicationAsRejected(jobApplication._id)"
									>
										<span
											v-if="
												disabledButtons.markAsRejected.has(jobApplication._id)
											"
											class="spinner-border spinner-border-sm"
										></span>
										<i v-else class="bi bi-x"></i>
									</button>
									<a
										v-if="jobApplication.jobLink"
										:href="jobApplication.jobLink"
										class="btn btn-sm btn-outline-secondary"
										target="_blank"
									>
										<i class="bi bi-link-45deg"></i>
									</a>
									<button
										type="button"
										class="btn btn-sm btn-outline-primary"
										data-bs-toggle="modal"
										data-bs-target="#applicationModal"
										@click="fillApplicationForm(jobApplication._id)"
									>
										<i class="bi bi-pencil"></i>
									</button>
								</div>
							</div>
						</div>
					</td>
				</tr>
			</tbody>
		</table>

		<h3>No response ({{ getJobApplicationsCategory("noresponse").length }})</h3>
		<div class="">
			<table class="table table-hover table-sm" style="table-layout: fixed">
				<thead>
					<tr>
						<th scope="col">Application</th>
					</tr>
				</thead>
				<tbody>
					<tr
						v-for="jobApplication in getJobApplicationsCategory('noresponse')"
						:key="'job-application-' + jobApplication._id"
					>
						<td>
							<div class="row">
								<div class="col-3 col-lg-2 col-xl-1">
									{{ formatDate(jobApplication.applicationDate) }}
								</div>
								<div class="col-9 col-lg-2">
									<template
										v-if="diffBetweenDates(jobApplication.applicationDate) == 0"
									>
										today
									</template>
									<template
										v-else-if="
											-diffBetweenDates(jobApplication.applicationDate) == 1
										"
									>
										yesterday
									</template>
									<template v-else>
										{{ -diffBetweenDates(jobApplication.applicationDate) }} days
										ago
									</template>
								</div>
								<div class="col-12 col-sm-3 col-lg-2 text-truncate">
									{{ jobApplication.companyName }}
								</div>
								<div class="col-8 col-sm-6 col-lg-4 col-xl-5 text-truncate">
									{{ jobApplication.jobTitle }}
								</div>
								<div class="col-4 col-sm-3 col-lg-2 text-end">
									<div class="btn-group" role="group">
										<button
											type="button"
											class="btn btn-sm btn-outline-danger"
											:disabled="
												disabledButtons.markAsRejected.has(jobApplication._id)
											"
											@click="setJobApplicationAsRejected(jobApplication._id)"
										>
											<span
												v-if="
													disabledButtons.markAsRejected.has(jobApplication._id)
												"
												class="spinner-border spinner-border-sm"
											></span>
											<i v-else class="bi bi-x"></i>
										</button>
										<a
											v-if="jobApplication.jobLink"
											:href="jobApplication.jobLink"
											class="btn btn-sm btn-outline-secondary"
											target="_blank"
										>
											<i class="bi bi-link-45deg"></i>
										</a>
										<button
											type="button"
											class="btn btn-sm btn-outline-primary"
											data-bs-toggle="modal"
											data-bs-target="#applicationModal"
											@click="fillApplicationForm(jobApplication._id)"
										>
											<i class="bi bi-pencil"></i>
										</button>
									</div>
								</div>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>

		<h3>Rejected or declined ({{ rejectedJobApplications.length }})</h3>
		<table class="table table-hover table-sm" style="table-layout: fixed">
			<thead>
				<tr>
					<th scope="col">
						<div class="row">
							<div class="col-lg-2 col-xl-1">Application</div>
							<div class="col-lg-10 col-xl-11">Rejected/declined after</div>
						</div>
					</th>
				</tr>
			</thead>
			<tbody>
				<tr
					v-for="jobApplication in rejectedJobApplications"
					:key="'job-application-' + jobApplication._id"
				>
					<td>
						<div class="row">
							<div class="col-3 col-lg-2 col-xl-1">
								{{ formatDate(jobApplication.applicationDate) }}
							</div>
							<div class="col-3 col-lg-2 col-xl-1">
								{{ daysBetweenApplicationRejection(jobApplication) }}
							</div>
							<div class="col-6 col-lg-2 pb-2 pb-lg-0 text-end">
								<span
									v-for="(step, i) in jobApplication.steps"
									:key="`waiting-job-application-${jobApplication._id}-step-${i}`"
								>
									<i
										class="me-2"
										:class="
											step.icon + ' ' + (!step.completed ? 'text-primary' : '')
										"
									/>
								</span>
							</div>
							<div class="col-12 col-sm-3 col-lg-2 text-truncate">
								{{ jobApplication.companyName }}
							</div>
							<div class="col-9 col-sm-6 col-lg-2 col-xl-4 text-truncate">
								{{ jobApplication.jobTitle }}
							</div>
							<div class="col-3 col-sm-3 col-lg-2 text-end">
								<div class="btn-group" role="group">
									<a
										v-if="jobApplication.jobLink"
										:href="jobApplication.jobLink"
										class="btn btn-sm btn-outline-secondary"
										target="_blank"
									>
										<i class="bi bi-link-45deg"></i>
									</a>
									<button
										type="button"
										class="btn btn-sm btn-outline-primary"
										data-bs-toggle="modal"
										data-bs-target="#applicationModal"
										@click="fillApplicationForm(jobApplication._id)"
									>
										<i class="bi bi-pencil"></i>
									</button>
								</div>
							</div>
						</div>
					</td>
				</tr>
			</tbody>
		</table>

		<div
			class="modal fade"
			id="applicationModal"
			tabindex="-1"
			aria-labelledby="applicationModalLabel"
			aria-hidden="true"
		>
			<div class="modal-dialog modal-xl">
				<div class="modal-content" v-if="applicationForm">
					<form @submit="submitApplicationForm">
						<div class="modal-header">
							<h5 class="modal-title" id="applicationModalLabel">
								Application
							</h5>
							<button
								type="button"
								class="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
							></button>
						</div>
						<div class="modal-body">
							<div class="row">
								<div class="col-lg-6">
									<div class="mb-3">
										<label for="companyName" class="form-label"
											>Company name</label
										>
										<input
											type="text"
											class="form-control"
											id="companyName"
											v-model="applicationForm.companyName"
										/>
									</div>
									<div class="mb-3">
										<label for="jobTitle" class="form-label">Job title</label>
										<input
											type="text"
											class="form-control"
											id="jobTitle"
											v-model="applicationForm.jobTitle"
										/>
									</div>
									<div class="mb-3">
										<label for="jobDescriptionLink" class="form-label"
											>Job description link</label
										>
										<input
											type="text"
											class="form-control"
											id="jobDescriptionLink"
											v-model="applicationForm.jobLink"
										/>
									</div>
									<div class="mb-3">
										<label for="notes" class="form-label">Notes</label>
										<textarea
											class="form-control"
											id="notes"
											rows="5"
											v-model="applicationForm.notes"
										></textarea>
									</div>
									<div class="mb-3">
										<label class="form-label">Application date</label>
										<input
											type="date"
											class="form-control"
											v-model="applicationForm.applicationDate"
										/>
									</div>
									<div class="row">
										<div class="col-xl-6 col-lg-12 col-sm-6 mb-3">
											<div class="form-check mb-3">
												<input
													class="form-check-input"
													type="checkbox"
													id="rejected"
													v-model="applicationForm.rejected"
													@change="
														applicationForm.declined = applicationForm.rejected
															? false
															: applicationForm.declined
													"
												/>
												<label class="form-check-label" for="rejected">
													Rejected (by company)
												</label>
											</div>
											<div v-if="applicationForm.rejected" class="mb-3">
												<label class="form-label">Rejection date</label>
												<input
													type="date"
													class="form-control"
													v-model="applicationForm.rejectionDate"
												/>
											</div>
										</div>
										<div class="col-xl-6 col-lg-12 col-sm-6 mb-3">
											<div class="form-check mb-3">
												<input
													class="form-check-input"
													type="checkbox"
													id="declined"
													v-model="applicationForm.declined"
													@change="
														applicationForm.rejected = applicationForm.declined
															? false
															: applicationForm.rejected
													"
												/>
												<label class="form-check-label" for="declined">
													Declined (by you)
												</label>
											</div>
											<div v-if="applicationForm.declined" class="mb-3">
												<label class="form-label">Declined date</label>
												<input
													type="date"
													class="form-control"
													v-model="applicationForm.declinedDate"
												/>
											</div>
										</div>
									</div>
								</div>
								<div class="col-lg-6">
									<h2>Steps</h2>
									<div
										v-for="(step, i) in applicationForm.steps"
										:key="'step-' + i"
										class="card mb-2"
									>
										<div class="card-header d-flex justify-content-between">
											<div class="dropdown">
												<button
													class="btn btn-outline-primary dropdown-toggle"
													type="button"
													id="todoTypeDropdown"
													data-bs-toggle="dropdown"
													aria-expanded="false"
												>
													Type:
													<i :class="step.icon"></i>
													{{
														step.icon == "bi bi-code-slash"
															? "Online ass.mt"
															: step.icon == "bi bi-telephone"
															? "Screening"
															: step.icon == "bi bi-gear"
															? "Tech. interview"
															: step.icon == "bi bi-building"
															? "Onsite"
															: step.icon == "bi bi-cash"
															? "Offer"
															: "N/A"
													}}
												</button>
												<ul
													class="dropdown-menu"
													aria-labelledby="todoTypeDropdown"
													style="cursor: pointer"
												>
													<li>
														<a
															class="dropdown-item"
															:class="step.icon == '' ? 'active' : ''"
															@click="step.icon = ''"
															>N/A</a
														>
													</li>
													<li>
														<a
															class="dropdown-item"
															:class="
																step.icon == 'bi bi-code-slash' ? 'active' : ''
															"
															@click="step.icon = 'bi bi-code-slash'"
														>
															<i class="bi bi-code-slash"></i> Online assessment
														</a>
													</li>
													<li>
														<a
															class="dropdown-item"
															:class="
																step.icon == 'bi bi-telephone' ? 'active' : ''
															"
															@click="step.icon = 'bi bi-telephone'"
														>
															<i class="bi bi-telephone"></i> Screening
														</a>
													</li>
													<li>
														<a
															class="dropdown-item"
															:class="step.icon == 'bi bi-gear' ? 'active' : ''"
															@click="step.icon = 'bi bi-gear'"
														>
															<i class="bi bi-gear"></i> Technical interview
														</a>
													</li>
													<li>
														<a
															class="dropdown-item"
															:class="
																step.icon == 'bi bi-building' ? 'active' : ''
															"
															@click="step.icon = 'bi bi-building'"
														>
															<i class="bi bi-building"></i> Onsite interview
														</a>
													</li>
													<li>
														<a
															class="dropdown-item"
															:class="step.icon == 'bi bi-cash' ? 'active' : ''"
															@click="step.icon = 'bi bi-cash'"
														>
															<i class="bi bi-cash"></i> Offer
														</a>
													</li>
												</ul>
											</div>

											<button
												type="button"
												@click="removeApplicationFormStep(i)"
												class="btn btn-outline-danger"
											>
												<i class="bi bi-trash"></i>
											</button>
										</div>
										<div class="card-body">
											<template v-if="step.type == 'todo'">
												<div class="row">
													<div class="col-xl-6 col-lg-12 col-sm-6 mb-3">
														<label class="form-label">Proposed on</label>
														<input
															type="date"
															class="form-control"
															v-model="step.date"
														/>
													</div>
													<div class="col-xl-6 col-lg-12 col-sm-6 mb-3">
														<label class="form-label">To do on/by</label>
														<input
															type="date"
															class="form-control"
															v-model="step.expirationDate"
														/>
													</div>
												</div>
												<div class="form-check mb-3">
													<input
														class="form-check-input"
														type="checkbox"
														:id="`step-${i}-completed`"
														v-model="step.completed"
													/>
													<label
														class="form-check-label"
														:for="`step-${i}-completed`"
													>
														Completed
													</label>
												</div>
												<div v-if="step.completed" class="mb-3">
													<label class="form-label">Completed on</label>
													<input
														type="date"
														class="form-control"
														v-model="step.completedDate"
													/>
												</div>
												<label class="form-label">Notes</label>
												<textarea
													class="form-control"
													v-model="step.notes"
												></textarea>
											</template>
										</div>
									</div>
									<div class="text-center">
										<button
											class="btn btn-secondary"
											type="button"
											@click="addApplicationFormStep('todo')"
										>
											<i class="bi bi-plus"></i>
											Add step
										</button>
									</div>
								</div>
							</div>
						</div>
						<div class="modal-footer">
							<button
								v-if="applicationForm._id"
								type="button"
								class="btn btn-outline-danger"
								@click="confirmDeleteJobApplication(applicationForm._id)"
							>
								Delete
							</button>
							<button
								type="button"
								class="btn btn-secondary"
								data-bs-dismiss="modal"
								ref="jobApplicationModalClose"
							>
								Close
							</button>
							<button
								type="submit"
								class="btn btn-primary"
								style="min-width: 80px"
								:disabled="applicationForm.loading"
							>
								<span
									v-if="applicationForm.loading"
									class="spinner-border spinner-border-sm"
								></span>
								<span v-else>Save</span>
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import { mapState, mapActions } from "vuex";
import moment from "moment";

export default {
	name: "Dashboard",
	data() {
		return {
			applicationForm: null,
			disabledButtons: {
				markAsDone: new Set(),
				markAsRejected: new Set(),
			},
		};
	},
	async created() {
		try {
			if (this.jobApplications) {
				this.fetchJobApplications();
			} else {
				await this.fetchJobApplications();
			}
		} catch (e) {
			alert(e);
		}
	},
	computed: {
		...mapState({
			jobApplications: (state) => state.jobApplications.jobApplications,
		}),
		waitingForResponseJobApplications() {
			return Object.values(this.jobApplications)
				.filter(
					(jobApplication) =>
						this.classifyJobApplication(jobApplication) == "waiting"
				)
				.sort((a, b) =>
					this.getLastCompletedStep(b).completedDate?.localeCompare(
						this.getLastCompletedStep(a).completedDate
					)
				);
		},
		rejectedJobApplications() {
			return Object.values(this.jobApplications)
				.filter(
					(jobApplication) =>
						this.classifyJobApplication(jobApplication) == "rejected"
				)
				.sort(this.sortJobApplications);
		},
		todoJobApplications() {
			return Object.values(this.jobApplications)
				.filter(
					(jobApplication) =>
						this.classifyJobApplication(jobApplication) == "todo"
				)
				.sort((a, b) =>
					// N/A expiration date must appear last
					this.getFirstNextStep(a).expirationDate == ""
						? 1
						: this.getFirstNextStep(b).expirationDate == ""
						? -1
						: // Sort by expiration date asc (closest date first)
						  this.getFirstNextStep(a).expirationDate?.localeCompare(
								this.getFirstNextStep(b).expirationDate
						  )
				);
		},
	},
	methods: {
		...mapActions("jobApplications", [
			"fetchJobApplications",
			"createJobApplication",
			"updateJobApplication",
			"deleteJobApplication",
		]),
		getJobApplicationsCategory: function (type) {
			if (type == "noresponse") {
				return Object.values(this.jobApplications)
					.filter(
						(jobApplication) =>
							this.classifyJobApplication(jobApplication) == "noresponse"
					)
					.sort(this.sortJobApplications);
			}
			return [];
		},
		classifyJobApplication: function (jobApplication) {
			if (jobApplication.rejected == true || jobApplication.declined == true)
				return "rejected";

			for (let step of jobApplication.steps) {
				if (
					step.completed === false ||
					(step.type == "interview" &&
						moment().format("YYYY-MM-DD").localeCompare(step.date) <= 0)
				) {
					return "todo";
				}
			}

			if (jobApplication.steps.length > 0) return "waiting";

			return "noresponse";
		},
		formatDate: function (date) {
			return moment(date).format("DD/MM/YY");
		},
		getFirstNextStep: function (jobApplication) {
			// Return the first uncompleted step
			for (let step of jobApplication.steps) {
				if (!step.completed) {
					return step;
				}
			}
			return null;
		},
		getLastCompletedStep: function (jobApplication) {
			// Copy array before reversing: reverse is in place
			let reversedSteps = [...jobApplication.steps].reverse();

			// Return the first completed step (which is the last one in the original array)
			for (let step of reversedSteps) {
				if (step.completed) {
					return step;
				}
			}
			return null;
		},
		diffBetweenDates: function (date1, date2, expressIn = "days") {
			date1 = moment(date1);
			date2 = moment(date2);
			return Math.ceil(date1.diff(date2, expressIn, true));
		},
		daysBetweenApplicationRejection: function (jobApplication) {
			if (jobApplication.rejectionDate != "") {
				return (
					moment(jobApplication.rejectionDate).diff(
						moment(jobApplication.applicationDate),
						"days"
					) + " days"
				);
			}
			return "";
		},
		sortJobApplications: function (jobApplication1, jobApplication2) {
			let strDiff = jobApplication2.applicationDate?.localeCompare(
				jobApplication1.applicationDate
			);
			if (strDiff == 0)
				return jobApplication2._id?.localeCompare(jobApplication1._id);
			return strDiff;
		},
		markFirstTodoStepAsDone: async function (jobApplicationId) {
			if (confirm("Do you want to mark this step as done today?")) {
				this.disabledButtons.markAsDone.add(jobApplicationId);
				try {
					let jobApplication = JSON.parse(
						JSON.stringify(this.jobApplications[jobApplicationId])
					);

					for (let step of jobApplication.steps) {
						if (!step.completed) {
							step.completed = true;
							step.completedDate = moment().format("YYYY-MM-DD");
							break;
						}
					}
					delete jobApplication["_id"];
					await this.updateJobApplication({
						jobApplication,
						jobApplicationId,
					});
				} catch (e) {
					alert(e);
				}

				this.disabledButtons.markAsDone.delete(jobApplicationId);
			}
		},
		setJobApplicationAsRejected: async function (jobApplicationId) {
			if (confirm("Do you want to mark this application as rejected today?")) {
				this.disabledButtons.markAsRejected.add(jobApplicationId);
				try {
					let jobApplication = JSON.parse(
						JSON.stringify(this.jobApplications[jobApplicationId])
					);
					jobApplication.rejected = true;
					jobApplication.rejectionDate = moment().format("YYYY-MM-DD");
					delete jobApplication["_id"];
					await this.updateJobApplication({
						jobApplication,
						jobApplicationId,
					});
				} catch (e) {
					alert(e);
				}

				this.disabledButtons.markAsRejected.delete(jobApplicationId);
			}
		},
		clearApplicationForm: function () {
			this.applicationForm = {
				_id: null,
				loading: false,

				companyName: "",
				jobTitle: "",
				jobLink: "",
				notes: "",
				applicationDate: moment().format("YYYY-MM-DD"),
				rejected: false,
				rejectionDate: "",
				declined: false,
				declinedDate: "",
				steps: [],
			};
		},
		fillApplicationForm: function (id) {
			this.applicationForm = {
				...JSON.parse(JSON.stringify(this.jobApplications[id])),
				loading: false,
			};
		},
		addApplicationFormStep: function (type) {
			if (type == "application") {
				this.applicationForm.steps.push({
					type,
					date: moment().format("YYYY-MM-DD"),
				});
			} else if (type == "challenge") {
				this.applicationForm.steps.push({
					type,
					date: moment().format("YYYY-MM-DD"),
					expirationDate: "",
					completed: false,
				});
			} else if (type == "interview") {
				this.applicationForm.steps.push({
					type,
					date: "",
				});
			} else if (type == "offer") {
				this.applicationForm.steps.push({
					type,
					date: moment().format("YYYY-MM-DD"),
					expirationDate: "",
				});
			} else if (type == "todo") {
				this.applicationForm.steps.push({
					type,
					icon: "",
					date: moment().format("YYYY-MM-DD"),
					expirationDate: "",
					completed: false,
				});
			}
		},
		removeApplicationFormStep: function (stepIndex) {
			this.applicationForm.steps.splice(stepIndex, 1);
		},
		submitApplicationForm: async function (e) {
			e.preventDefault();
			this.applicationForm.loading = true;
			let applicationForm = JSON.parse(JSON.stringify(this.applicationForm));
			let id = applicationForm._id;
			delete applicationForm["loading"];
			delete applicationForm["_id"];

			try {
				if (id) {
					await this.updateJobApplication({
						jobApplication: applicationForm,
						jobApplicationId: id,
					});
				} else {
					await this.createJobApplication(applicationForm);
				}
				this.$refs.jobApplicationModalClose.click();
			} catch (e) {
				alert(e);
			}

			this.applicationForm.loading = false;
		},
		confirmDeleteJobApplication: async function (id) {
			if (confirm("Do you really want to delete this job application?")) {
				await this.deleteJobApplication(id);
				this.$refs.jobApplicationModalClose.click();
			}
		},
	},
};
</script>

<style scoped>
.bg-icon-circle {
	padding: 0 5px 5px 5px;
	border-radius: 50%;
}
</style>
