import api from '../../api/job-applications'

// initial state
const state = () => ({
  jobApplications: null, // filtered
  allJobApplications: {},
  jobApplicationsSearchQuery: ''
})

// getters
const getters = {
  jobApplications: (state) => state.jobApplications,
  allJobApplications: (state) => state.allJobApplications,
  jobApplicationsSearchQuery: (state) => state.jobApplicationsSearchQuery,
}

// actions
const actions = {
  async fetchJobApplications({ commit, dispatch, getters }) {
    let fetchedJobApplications = await api.fetchJobApplications()
    let jobApplications = {}
    for (let jobApplication of fetchedJobApplications) {
      jobApplications[jobApplication._id] = jobApplication
    }

    commit('setJobApplications', jobApplications)
    commit('setAllJobApplications', { ...jobApplications })
    dispatch('setJobApplicationsSearchQuery', getters.jobApplicationsSearchQuery)
  },
  async updateJobApplication({ commit, dispatch, getters }, { jobApplication, jobApplicationId }) {
    await api.updateJobApplication({ jobApplication, jobApplicationId })

    let allJobApplications = JSON.parse(JSON.stringify(getters.allJobApplications))
    allJobApplications[jobApplicationId] = {
      ...allJobApplications[jobApplicationId],
      ...JSON.parse(JSON.stringify(jobApplication))
    }

    commit('setJobApplications', allJobApplications)
    commit('setAllJobApplications', { ...allJobApplications })
    dispatch('setJobApplicationsSearchQuery', getters.jobApplicationsSearchQuery)
  },
  async createJobApplication({ commit, dispatch, getters }, jobApplication) {
    let jobApplicationId = await api.createJobApplication(jobApplication)

    let allJobApplications = JSON.parse(JSON.stringify(getters.allJobApplications))
    allJobApplications[jobApplicationId] = JSON.parse(JSON.stringify({
      ...jobApplication,
      _id: jobApplicationId
    }))

    commit('setJobApplications', allJobApplications)
    commit('setAllJobApplications', { ...allJobApplications })
    dispatch('setJobApplicationsSearchQuery', getters.jobApplicationsSearchQuery)
  },
  async deleteJobApplication({ commit, dispatch, getters }, jobApplicationId) {
    await api.deleteJobApplication(jobApplicationId)

    let allJobApplications = JSON.parse(JSON.stringify(getters.allJobApplications))
    delete allJobApplications[jobApplicationId]

    commit('setJobApplications', allJobApplications)
    commit('setAllJobApplications', { ...allJobApplications })
    dispatch('setJobApplicationsSearchQuery', getters.jobApplicationsSearchQuery)
  },
  setJobApplicationsSearchQuery({ commit, getters }, query) {
    commit('setJobApplicationsSearchQuery', query)

    let filteredJobApplications = Object.assign({},
      ...Object.entries(getters.allJobApplications).filter(([, jobApplication]) => {
        if (query === '') return true
        return jobApplication.companyName.toLowerCase().includes(query.toLowerCase()) ||
          jobApplication.jobTitle.toLowerCase().includes(query.toLowerCase())
      }).map(([k, v]) => ({ [k]: v })))

    commit('setJobApplications', filteredJobApplications)
  }
}

// mutations
const mutations = {
  setJobApplications(state, jobApplications) {
    state.jobApplications = jobApplications
  },
  setAllJobApplications(state, allJobApplications) {
    state.allJobApplications = allJobApplications
  },
  setJobApplicationsSearchQuery(state, jobApplicationsSearchQuery) {
    state.jobApplicationsSearchQuery = jobApplicationsSearchQuery
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}